import * as React from "react"
import { useEffect } from 'react';
import SideBar from '../components/sidebar';
import SpeakWithAdvisor from '../components/ui/SpeakWithAdvisor';
import Layout from "../templates/layout-standard"
import Seo from "../components/seo"

const RequestAKit = ({ location }) => {

	useEffect (() => {
		window.MsCrmMkt = null;
		const dynFormScript = document.createElement("script");
		dynFormScript.src = "https://mktdplp102cdn.azureedge.net/public/latest/js/form-loader.js?v=1.76.2003.0";
		dynFormScript.async = false;
		document.body.appendChild(dynFormScript);

		const dynTrackScript = document.createElement("script");
		dynTrackScript.src = "https://mktdplp102cdn.azureedge.net/public/latest/js/ws-tracking.js?v=1.76.2003.0";
		dynTrackScript.async = false;
		document.body.appendChild(dynTrackScript);
	},[]);

	return (
		<Layout location={location} heading1="Request a UVM Health Advantage Plan Benefit Kit" heading2="">
			<Seo title="Request a Free Plan Benefit Kit" meta={[{description: 'Get the information you need to find the right Medicare plan for you.'}]} bodyclass="request-a-kit subpage" />
			
			<div className="constrained">
				<div className="content-constrained">
					<div className="flexwrap_subpage">
					
						<main>
							<h2 className="like-h3">Get the information you need to find the right Medicare plan for you.</h2>
							<ul>
								<li>Which plans cover the services you need?</li>
								<li>Which plans have out-of-network options?</li>
								<li>How do you compare costs of Medicare plans?</li>
								<li>What types of prescription coverage are available?</li>
							</ul>

							<h3 className="small">Request your UVM Health Advantage Plan Benefit kit today!</h3>
							
							<p dangerouslySetInnerHTML={{ __html: process.env.GATSBY_REQUEST_A_KIT_MS }}></p>
														
							<SpeakWithAdvisor />
						</main>

						<SideBar location={location} />
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default RequestAKit
